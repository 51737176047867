import {
  PseudoStatusEnum,
  PseudoStatusDescriptionEnum
} from '@models/premier-service.model';
import { CartItemBaseModel } from '@models/cart-item-base.model';

export class PremiumAdModel extends CartItemBaseModel {
  amountId: number;
  basicDuration: number;
  categories: string[];
  dateActivationPostponeAttempts: number;
  isAnimatedThumbnail: number;
  isGroupAd: number;
  isRecurring: 0 | 1;
  isRenew: number;
  isShortTermExtended: number;
  isVideo: number;
  isVip: number;
  orderDateCreated: Date;
  orderId: number;
  status: PremiumAdStatusEnum;
  isWorldwide?: boolean;
  isOldLocation?: boolean;
  dateActivationConverted?: string;
  dateExpirationConverted?: string;
  dateRevealConverted?: string;
  dateExpirationTime?: string;
  expiresIn?: number;
  isRenewable?: boolean;
  inCart?: boolean;
  isRealDurationVisible?: boolean;
  realDuration?: number;
  locationName?: string;
  pseudoStatus?: PseudoStatusEnum;
  pseudoStatusDescription?: PseudoStatusDescriptionEnum;
  notAssigned?: boolean;
  bump?: number;
  indexedText?: string;
  hasId?: boolean;
  payed?: boolean;
  bumped?: boolean;
  dateBumped?: Date;

  isOptionsShown?: boolean;

  static fromJson(json): PremiumAdModel {
    return {
      id: json.id,
      accountId: json.account_id,
      adId: json.ad_id,
      amount: json.amount,
      amountId: json.amount_id,
      basicDuration: json.basic_duration,
      categories: json.categories ?? [],
      createdBy: json.created_by,
      currency: json.currency,
      dateActivation: json.date_activation,
      dateExpiration: json.date_expiration,
      duration: json.duration,
      dateActivationPostponeAttempts: json.date_activation_postpone_attemps,
      discountAppliedBy: json.discount_applied_by,
      discountDateApplied: json.discount_date_applied
        ? new Date(json.discount_date_applied)
        : null,
      discountId: json.discount_id,
      discountPercentage: json.discount_percentage,
      discountReasonId: json.discount_reason_id,
      discountType: json.discount_type,
      exchangeRate: json.exchange_rate,
      finalAmount: json.final_amount,
      inventoryId: json.inventory_id,
      isAnimatedThumbnail: json.is_animated_thumbnail,
      isGroupAd: json.is_group_ad,
      isRecurring: json.is_recurring,
      isRenew: json.is_renew,
      isShortTermExtended: json.is_short_term_extended,
      isVideo: json.is_video,
      isVip: json.is_vip,
      locationId: json.location_id,
      orderDateCreated: json.order_date_created
        ? new Date(json.order_date_created)
        : null,
      orderId: json.order_id,
      status: json.status,
      systemAmount: json.system_amount,
      upgradedDuration: json.upgraded_duration,
      systemFinalAmount: json.system_final_amount,
      upgradedFrom: json.upgraded_from,
      isPremiumAd: true,
      dateBumped: json.date_bumped ? new Date(json.date_bumped) : null
    };
  }
}

export enum PremiumAdStatusEnum {
  Active = 'active',
  Pending = 'pending',
  Expired = 'expired',
  Canceled = 'canceled',
  Future = 'future',
  NA = 'n/a',
  NotApproved = 'not approved'
}
