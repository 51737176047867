import { ProfileModel, ProfilePhotoModel } from '@models/profile.model';
import {
  IPremiumAdPrice,
  ServicesCurrencyEnum,
  ServicesDiscountTypeEnum
} from '@models/premier-service.model';
import { IInventoryData } from '@app/pages/my/features/profile/pages/data-access/profile.model';
import { ProfileMainCategoryEnum } from '@models/profile-form-data.model';

export abstract class CartItemBaseModel {
  id: number;
  accountId: number;
  adId: string;
  createdBy: number;
  amount: number;
  exchangeRate: number;
  currency: ServicesCurrencyEnum;
  systemAmount: number;
  systemFinalAmount: number;
  finalAmount: number;
  discountId: number;
  discountPercentage: number;
  discountReasonId: number;
  discountType: ServicesDiscountTypeEnum;
  discountDateApplied: Date;
  discountAppliedBy: number;
  locationId: number;
  timezone?: string;
  locationFormatted?: string;
  revision?: ProfileModel;
  profile?: ProfileModel;
  refId?: number;
  isOnline?: boolean;
  image?: ProfilePhotoModel;
  isPremiumAd?: boolean;
  durations?: IInventoryData;
  standartDurations?: number[];
  visitingDurations?: number[];
  visitingAdminDurations?: number[];
  baseAdminDurations?: number[];
  standardMin?: number;
  visitingMin?: number;
  inventoryId?: number;
  isPremiumService?: boolean;
  isBump?: boolean;
  upgradedDuration?: number;
  duration?: number;
  renewedDuration?: number;
  pattern?: string;
  renewedFrom?: number;
  isDateExpirationAvailable?: boolean;
  dateCreated?: Date;
  upgradedFrom?: Date | string;
  dateExpiration?: Date | string;
  dateActivation?: Date | string;
  runDateStart?: Date | string;
  runDateEnd?: Date | string;
  pricing?: IPremiumAdPrice;
  name?: string;
  principalCategory?: ProfileMainCategoryEnum;
  secondaryCategory?: string;
  family?: string;
  location?: {
    path: string;
    timezone: string;
  };
}
