import { ProfileAvailableForModel, ProfileModel } from '@models/profile.model';
import { GeographyLeavesModel } from '@models/geography.model';
import { RatesModel } from '@models/rates.model';
import {
  ProfileMainCategoryEnum,
  ProfileSpecialtyCategoryEnum
} from '@models/profile-form-data.model';
import { FormControl } from '@angular/forms';
import { Component, Type } from '@angular/core';
import { PremiumAdStatusEnum } from '@models/premium-ad.model';
import { CartItemBaseModel } from '@models/cart-item-base.model';
import { PriceFeatureNameEnum } from '@models/price.model';

export class PremierServiceModel {
  availableSlotsCount: number;
  bannerMaxHeight: number;
  bannerMaxHeightMobile: number;
  bannerMaxWidth: number;
  bannerMaxWidthMobile: number;
  family: string;
  grouping: PremierServiceGroupingEnum;
  id: number;
  inWatchlist: boolean;
  isMoreLikeThis: number;
  name: string;
  numberOfSlots: number;
  price: number;
  principalCategory: ProfileMainCategoryEnum;
  principalCategoryId: number;
  productType: PremierServiceProductTypeEnum;
  runs: any[];
  secondaryCategory: string;
  secondaryCategoryId: number;
  typeId: number;
  watchlistId: number;
  description?: string;
  videoUrl?: string;
  imageUrl?: string;

  // in watchlist properties
  duration?: number;
  inventoryId?: number;
  lastAvailableOn?: Date;
  lastSoldOn?: Date;
  locationId?: number;
  usedSlots?: number;

  static fromJson(json: any): PremierServiceModel {
    return {
      availableSlotsCount: json.availableSlotsCount,
      bannerMaxHeight: json.banner_max_height,
      bannerMaxHeightMobile: json.banner_max_height_mobile,
      bannerMaxWidth: json.banner_max_width,
      bannerMaxWidthMobile: json.banner_max_width_mobile,
      family: json.family,
      grouping: json.grouping,
      id: json.id,
      inWatchlist: json.in_watchlist,
      isMoreLikeThis: json.is_more_like_this,
      name: json.name,
      numberOfSlots: json.number_of_slots,
      price: json.price,
      principalCategory: json.principal_category,
      principalCategoryId: json.principal_category_id,
      productType: json.product_type,
      runs: json.runs,
      secondaryCategory: json.secondary_category,
      secondaryCategoryId: json.secondary_category_id,
      typeId: json.type_id,
      watchlistId: json.watchlist_id,
      duration: json.duration,
      inventoryId: json.inventory_id,
      locationId: json.location_id,
      usedSlots: json.used_slots,
      lastAvailableOn: json.last_available_on
        ? new Date(json.last_available_on)
        : null,
      lastSoldOn: json.last_sold_on ? new Date(json.last_sold_on) : null
    };
  }
}

export interface IAdService {
  name: string;
  showName: string;
  showDesc: boolean;
  typeId: number;
  price: number;
  description: string;
  videoUrl: string;
  imageUrl: string;
  serviceName?: string;
  adId?: string;
  mainCat?: string;
  secCat?: string;
  inventoryId?: number;
  locationId?: number;
  fromWatchlist?: boolean;
  watchlistId?: number;
  disableSeeDetailsBtn?: boolean;
  newItem?: boolean;
  rowStyle?: boolean;
  component: Type<Component>;
}

export enum PremierServiceProductTypeEnum {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Daily = 'daily'
}

export enum PremierServiceGroupingEnum {
  Banner = 'banner'
}

export interface IPremierServiceResolverData {
  profiles: ProfileModel[];
  markets: GeographyLeavesModel[];
  rates: RatesModel;
  designedFor: PremierServiceDesignedForEnum;
}

export enum PremierServiceDesignedForEnum {
  PremierServices = 'premier-services',
  Watchlist = 'watchlist',
  ProfileBuilder = 'profile-builder'
}

export enum PremierServiceSearchByEnum {
  Location = 'location',
  Ad = 'ad'
}

export enum PremierServiceLinkToEnum {
  Ad = 'ad',
  Url = 'url'
}

export interface IPremiumServiceQuery {
  mainCategories?: string[];
  specialtyCategories?: string[];
  principalCategoryIds?: number[];
  secondaryCategoryIds?: number[];
  locationCategories?: string[];
  hairColor?: string;
  availableTo?: string[];
  availableFor?: ProfileAvailableForModel[];
  gender?: string;
  isVip?: boolean;
  ethnicities?: string[];
  affiliation?: string;
  cupSize?: string;
  isVisiting?: boolean;
  isVideo?: boolean;
  isAuthenticated?: boolean;
  isAvailableNow?: boolean;
  family?: string;
  serviceTypeIds?: number[];
  locationId?: number;
  locationIds?: number[];
}

export const buildPremiumServiceQueries = (q: IPremiumServiceQuery) => {
  const o = {
    main_categories: q.mainCategories,
    specialty_categories: q.specialtyCategories,
    location_categories: q.locationCategories,
    principal_category_ids: q.principalCategoryIds,
    secondary_category_ids: q.secondaryCategoryIds,
    hair_color: q.hairColor,
    available_to: q.availableTo,
    available_for: q.availableFor,
    gender: q.gender,
    is_vip: q.isVip,
    ethnicities: q.ethnicities,
    affiliation: q.affiliation,
    cup_size: q.cupSize,
    is_visiting: q.isVisiting,
    is_video: q.isVideo,
    is_authenticated: q.isAuthenticated,
    is_available_now: q.isAvailableNow,
    family: q.family,
    service_type_ids: q.serviceTypeIds,
    location_id: q.locationId,
    location_ids: q.locationIds
  };

  return Object.keys(o).reduce((acc, v) => {
    acc[v] = o[v];
    return acc;
  }, {});
};

export interface IPremierServiceRate {
  currency: string;
  rate: number;
}

export interface IPremierServiceDialogFormGroup {
  principalCategory: FormControl<string>;
  secondaryCategory: FormControl<string>;
  linkTo: FormControl<PremierServiceLinkToEnum>;
  selectedAd: FormControl<string>;
  bannerImage: FormControl<File>;
  bannerMobileImage: FormControl<File>;
  run: FormControl<string>;
  bannerUrl: FormControl<string>;
}

export interface IPremierServiceDialogFormData {
  principalCategory?: string;
  secondaryCategory?: string;
  linkTo?: PremierServiceLinkToEnum;
  selectedAd?: string;
  bannerImage?: File;
  bannerMobileImage?: File;
  run?: string;
  bannerUrl?: string;
}

export interface IPremierServiceImageDimension {
  img: {
    width: number;
    height: number;
  };
  mobileImg: {
    width: number;
    height: number;
  };
}

export const SERVICE_TYPE_ID = 16;
export const PR_SERVICE_LOCATION_ID = 1000001;

export class PremiumServiceModel extends CartItemBaseModel {
  bannerImageId: string | null;
  bannerMobileImageId: string | null;
  banner_url: string | null;
  isBanner: 0 | 1;
  isCreatedByForced: 0 | 1;
  isRecurring: 0 | 1;
  listPrice: number;
  orderId: number;
  status: PremiumAdStatusEnum;
  systemListPrice: number;
  pendingBanner: IPendingBanner;
  isWorldwide?: boolean;
  dateActivationConverted?: string;
  dateExpirationConverted?: string;
  dateRevealConverted?: string;
  dateExpirationTime?: string;
  expiresIn?: number;
  notAssigned?: boolean;
  isRenewable?: boolean;
  inCart?: boolean;
  isRealDurationVisible?: boolean;
  realDuration?: number;
  isOldLocation?: boolean;
  locationName?: string;
  pseudoStatus?: PseudoStatusEnum;
  pseudoStatusDescription?: PseudoStatusDescriptionEnum;
  indexedText?: string;
  hasUnapprovedBanner?: boolean;
  hasId?: boolean;

  static fromJSON(json: any): PremiumServiceModel {
    return {
      accountId: json.account_id,
      adId: json.ad_id || null,
      amount: json.amount,
      bannerImageId: json.banner_image_id || null,
      bannerMobileImageId: json.banner_mobile_image_id || null,
      banner_url: json.banner_url || null,
      createdBy: json.created_by,
      currency: json.currency,
      dateActivation: json.date_activation,
      dateExpiration: json.date_expiration,
      discountAppliedBy: json.discount_applied_by || null,
      discountDateApplied: json.discount_date_applied
        ? new Date(json.discount_date_applied)
        : null,
      discountId: json.discount_id || null,
      discountPercentage: json.discount_percentage || null,
      discountReasonId: json.discount_reason_id || null,
      discountType: json.discount_type || null,
      exchangeRate: json.exchange_rate,
      family: json.family,
      finalAmount: json.final_amount,
      id: json.id,
      inventoryId: json.inventory_id,
      isBanner: json.is_banner,
      isCreatedByForced: json.is_created_by_forced,
      isRecurring: json.is_recurring,
      listPrice: json.list_price,
      locationId: json.location_id,
      name: json.name,
      orderId: json.order_id,
      principalCategory: json.principal_category,
      runDateEnd: json.run_date_end ? new Date(json.run_date_end) : null,
      runDateStart: json.run_date_start ? new Date(json.run_date_start) : null,
      secondaryCategory: json.secondary_category || null,
      status: json.status,
      systemAmount: json.system_amount,
      systemFinalAmount: json.system_final_amount,
      systemListPrice: json.system_list_price,
      pendingBanner: json.pending_banner,
      renewedFrom: json.renewed_from,
      duration: json.duration,
      renewedDuration: json.renewed_duration,
      dateCreated: json.date_created,
      hasUnapprovedBanner: true,
      isPremiumService: true
    };
  }
}

export interface IPendingBanner {
  ad_id?: string;
  image_id: string;
  mobile_image_id: string;
  status: string;
  url?: string;
  premium_service_id?: number;
}

export enum ServicesCurrencyEnum {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD'
}

export enum ServicesDiscountTypeEnum {
  Auto = 'auto',
  PromoCode = 'promo code',
  Custom = 'custom'
}

export enum PseudoStatusEnum {
  Online = 'online',
  Offline = 'offline',
  Active = 'active',
  Pending = 'pending',
  Future = 'future',
  PaidNotOnline = 'paidNotOnline',
  PaidHidden = 'paidHidden',
  All = 'all',
  NotAssigned = 'notAssigned'
}

export enum PseudoStatusDescriptionEnum {
  OrderReturned = 'order returned',
  OrderUnderReview = 'order under review',
  NotSubmittedChanges = 'not submitted changes',
  OrderSaved = 'order saved'
}

export interface IPremiumAdPrice {
  base: number;
  basicDuration: number;
  bump: number;
  duration: number;
  features: IPremiumAdPriceItems[];
  identifier: string;
  items: IPremiumAdPriceItems[];
  total: number;
}

export interface IPremiumAdPriceItems {
  name:
    | PriceFeatureNameEnum
    | ProfileMainCategoryEnum
    | ProfileSpecialtyCategoryEnum
    | string;
  price: number;
  paid?: number | boolean;
}
